// Mixins
// -----------------------------------------------------------------------------


@mixin tab-for-voivodeship {
  display: none; border-bottom: solid 2px #fff;
  li { &,a {background: darken(#a0a2a3, 10);}
    &:hover { &, a { background: darken(#a0a2a3, 20);}}
    &.active { &, a { background: darken($green-light, 10);}
      &:hover { &, a { background: darken($green-light, 20);}}
    }
  }
  &.visible { display: block;}
}