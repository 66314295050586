// Styles for Menus and Navs
// -----------------------------------------------------------------------------
@import url('https://fonts.googleapis.com/css?family=Crete+Round');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

$CR: 'Crete Round', serif;
$OS: 'Open Sans', sans-serif;


$blue: #3f8096;
$gray-blue: #455a64;
$white: #ffffff;
$black : #000000;
$bg-gray: #e5e8e9;
$green-light: #92be1b;
$footer-bg: #263238;
$orange: #fe901d;

h2 { font-size: 50px; font-family: $CR; color: $blue;  }
h3 { font-size: 35px; font-family: $CR; color: $black; }

@mixin submit-button($margin-top, $border-radius, $background-color, $border, $font-size) {
  margin-top: $margin-top;
  border-radius:$border-radius;
  background-color: $background-color;
  border: $border;
  font-size: $font-size;
  color: #fff;
  text-transform: uppercase;
}
.navbar-default { border: none; background-color: #fff; }
.logged-in {
  header#navbar { margin-top: 30px; }
}
header#navbar { z-index: 100; height:104px; box-shadow: 0 4px 10px -2px;
  > .container { position: relative;}
  .navbar-header {
    .logo {
      img { width: 258px; transition-duration: 300ms;}
    }
  }
  .navbar-toggle { border: none; background: transparent; margin: 30px 10px 0 0;
    span.icon-bar { background: #000; height: 4px; width: 30px;}
    &:hover { background: transparent;}
  }
  .region-navigation {
    ul.menu.nav { display: inline-block; margin-left: 80px; padding:25px; transition-duration: 300ms;
      li { display: inline-block;
        &.active { a { color: $blue; background: transparent;} background: transparent;}
        a { font-size: 20px; font-family: $CR; text-transform: uppercase; color:#000;
        &:hover, &:focus { background: transparent; color: $blue;}
        }
      }
    }
    //menu for volunteers
    .volunteer_menu {
      background-color: $volnteer-blue;  width: 100%; float: left; position: relative;
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 75px;
        background: $volnteer-blue;
        left: -99%;
      }
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 75px;
        background: $volnteer-blue;
        left: 99%;
        top: 0;
      }
      ul.menu {
        max-width: 100%; margin: 0 auto; padding: 10px 15px; width:1170px; float: none;
        li {
          a {
            color: #fff;
            text-transform: uppercase;
            transition: all 500ms;
            font-size: 18px;
            cursor: pointer;
            font-family: "Helvetica";
            &.join-us { background-color: #f8b418;}
            &:hover, &:focus { color: $volnteer-blue; background-color: #fff; }
          }
          &.active a { background-color: #fff;  color: $volnteer-blue; transition:all 500ms; }
        }
      }
    }
  }
  &.sticky {
    height: 70px;
    transition-duration: 300ms;
    .navbar-header {
      .logo {
        img { width:150px; transition-duration: 300ms;}
      }
    }
    .navbar-collapse {
      ul.menu.nav { padding: 10px 25px; transition-duration: 300ms;}
    }
  }
}
.top-content {
  .video-background { background-image: url('../../image/bg-video.png'); height: 698px; width: 100%; position: relative;
    .head { position:absolute; top: 30%;
      h2 { color: $white; }
      ul {
        li {  font-size: 21px; font-family: $OS; color: $white;}
      }

      a.btn { display: inline-block; color: $white; border: 1px solid $white; border-radius: 0;  text-transform: uppercase; font-size: 16px; font-family: $OS; font-weight: 300; padding: 12px 25px; margin-top: 40px; }
    }
  }
  #onas { background-color: $bg-gray; overflow: hidden; padding-top: 40px; padding-bottom: 90px;
    p { font-family: $OS; font-size: 16px; }
    .about-images { position: relative; padding-left: 0; min-height: 320px;}
    .about-text { padding-left: 80px;}
    img { border-radius: 50%;
      &:first-child { position: relative; width: 70%; top: 60px;}
      &:last-child { position: absolute; top: 180px;  width: 60%; right: 0; border: solid 6px $bg-gray;}
    }
    h2 { padding-top: 25px; text-transform: uppercase; }


  }
  .offer-block { clear: both;}
  .view-ekspert-oferta { margin-bottom: 80px;
    h2 { text-transform: uppercase; margin-top: 75px; margin-bottom: 50px;  }
    .oferta { position: relative; margin-bottom: 20px;
      img { max-width: 100%; position: relative; }
      .oferta-tekst { padding: 30px 20px 0; position: absolute; top: 0; right: 0; width: 60%; height: 100%; background-color: rgba(0,0,0,0.65); color: #fff; font-family: $OS; font-weight: 300; font-size: 14px;
        h4 {  font-family: $CR; font-size:25px;
          a{ color: $white; }
        }
        .more a { display: inline-block; color: $white; border: 1px solid; border-radius:0; padding: 10px 80px; background-color: transparent; transition: all 500ms; margin-top: 10px; font-size: 16px;
          &:hover { text-decoration: none;  background-color: $green-light; border:1px solid $green-light; transition: all 500ms; cursor: pointer; }
        }
      }
    }
  }
  .baner-prow { background-image: url('../../image/prow-bg.png'); background-size:100% 100%; padding: 80px 0; z-index: 9;
    .prow-head {
      background-color: rgba(161, 171, 175, 0.75);
      box-shadow: 0 4px 10px -2px;
      h2 { padding: 10px; margin-bottom: 0; color: $white; font-weight: 300;}
      img { max-width: 118px; position: relative; top: -20px; padding: 0; margin-right: 20px; margin-left: 20px;}
    }

    .prow-text { background-color: rgba(69,90,100,0.6); text-align: center; padding:50px 0;
      p { font-size: 21px; color: $white; font-weight: 300;
        span { font-size: 28px; font-weight: 400; }
      }
      a { color: $white; display: inline-block; padding: 10px 60px; border: 1px solid; font-size: 16px; text-transform: uppercase; transition: all 500ms;
        &:hover { text-decoration: none;  background-color: $green-light; border:1px solid $green-light; transition: all 500ms; cursor: pointer; }
      }


    }
  }
  .ebook-block { background: $bg-gray; clear: both;}
  .view-id-ebook { padding-top: 100px;
    h2 { margin-bottom: 60px;}
    .view-content { margin-top: 60px; }
    .node-webform {
      .group-header { width:50%; float:right; text-align: center;
        h3 { text-transform: uppercase; margin-bottom: 50px; }
        p { font-size: 27px;
          span { font-size: 22px; display: block; margin-top: 0;}
        }
      }
      .group-right {
        .form-control {  height: 50px; width:75%; border-radius: 0; margin: 0 auto; text-align: center; margin-top: 25px; }
        .form-actions {
          .webform-submit { @include submit-button(25px, 0, $green-light, none, 16px); padding: 10px 55px;}
        }
      }
    }
  }
  .view-ekspert-partnerzy { margin-top: 100px; margin-bottom: 100px;
    h2 { text-transform: uppercase; }
  }
  .odzialy { background-image: url('../../image/odzialy-bg.png'); }
  .view-odzia-y { padding-bottom: 20px;
    .view-header {
      h2 { color: $white; text-transform: uppercase; margin-top: 80px; margin-bottom: 70px; }
      svg {
        max-width: 100%; width: 800px;
        //width: 100%; height: 700px;
        .land { fill: #CCCCCC; fill-opacity: 1; stroke:white; stroke-opacity: 1; stroke-width:0.5; }
        path.area-with-city:hover {
          fill: (lighten($green-light, 20));
        }
        path.active { fill:$green-light; }
        path.amcharts-map-image { fill: $orange;}
        text tspan { font-size: 24px; font-weight: bold;}
      }
      .click-map { font-size: 32px; color: #fff; position: relative; top: -45px; left: 10px; font-family: $CR;}
    }
    .view-content {
      ul#pl-pd-nav { @include tab-for-voivodeship; }
      ul#pl-mz-nav { float: none; width: 100%; @include tab-for-voivodeship;
        li {
          width: 33%;
          display: table-cell;
          float: none;
          vertical-align: middle;
          border-right: solid 2px #fff;
          &:last-of-type { border-right: none;}
        }
      }
      .oddzial {
        .filia-content { width: 300px; max-width: 100%; margin: 0 auto;
          h3 { text-align: center; text-transform: uppercase; }
          .adres, .kontakt, .otwarcie { position: relative; margin: 25px auto 0 auto; padding-left: 80px;
            a { color: #000; &:hover {color: $green-light; text-decoration: none;}}

            &:before { content:" "; display: inline-block; position: absolute; left: 10px ;width: 45px; height: 42px; }
            .field-label { font-weight: normal; display: inline-block; }
            .field-items { display: inline-block; }
          }

          .adres:before { background-image:url('../../image/adres.png'); }
          .kontakt:before { background-image:url('../../image/message.png'); }
          .otwarcie:before { background-image:url('../../image/open.png');}

          &>a { display: block; border: 1px solid $gray-blue; text-align: center; padding: 10px; text-transform: uppercase; text-decoration: none; color: $gray-blue; margin-top:70px; margin-bottom: 40px; transition: all 500ms;
            &:hover { background-color: $green-light; border-color: $green-light; color: $white; }
          }
        }
        background-color:#fff; padding: 20px 10px;

      }
      .active { display: block; }

      .field-collection-item-field-social-media {
        .field-type-link-field { display: inline-block; width: 33%;
          .field-items { text-align: center;}
        }
        a { font-size: 0;
          &:before {
            content: "";
            display: inline-block;
            height: 51px;
            width: 55px;
            background-repeat: no-repeat;
            background-size: contain;
            transition: all 500ms;
          }
          &:hover { opacity: 0.75; transition: all 500ms; }

        }
        a.tw:before { background-image: url('../../image/tw.png');  }
        a.fb:before { background-image: url('../../image/fb.png'); }
        a.plus:before { background-image: url('../../image/plus.png'); }
      }

      .data-place { padding-bottom: 30px;
        .filia { display: none;
          &.visible{display: block;}}
        .nav-tabs {
          margin: 0; border: none;
          & > li {
            width: 50%;
            background-color: #a0a2a3;
            line-height: 1.42857;
            color: #fff;
            border-radius: 0;
            border: none;
            text-align: center;
            text-transform: uppercase;
            margin-right: 0;
            transition: all 500ms;
            &:first-of-type { border-right: solid 2px #fff;}
            &.active > a {
              background-color: $green-light;
              transition: all 500ms;
              &:hover { background: darken($green-light, 15);}
            }
            a { color: #fff; border: none; margin-right: 0; border-radius: 0;
              &:hover { background: darken(#a0a2a3, 15);}
            }
          }
        }
      }
      .oddzial, .realizations-in-poland {
        min-height: 500px; background: #fff;
      }
      .realizations-in-poland { padding: 20px ;
        h3 { text-align: center; text-transform: uppercase; margin-bottom: 25px;} }
      .view-realization-of-branch { margin:0;
        .realization {
          .row { margin-bottom: 20px;
            .img-responsive { padding-left: 0; img{ width: 100%; height: inherit;} }
            //.col-xs-12.col-sm-6{ padding: 0;}
            div:nth-of-type(2){ padding: 0;}
            h4 { font-size: 16px; margin-top: 0; padding-bottom: 10px;
              a { color: #000; font-family: $CR; cursor: pointer;
                &:hover { color :$green-light; text-decoration: none;}
              }
            }
          }
          a.read-more { color: #000; border-radius: 0; border: solid 1px #000; padding: 8px; cursor: pointer; font-size: 13px; font-weight: bold;
            &:hover { text-decoration: none; background-color: $green-light; color: #fff; border-color: $green-light;}}
        }
      }
    }
    #realizacje { background: #fff; padding: 20px;}
  }


}
footer { color: $white; background-color: $footer-bg; padding: 50px 0;
  .view-footer-form {
    h3 { color: $white; margin-bottom:30px;  }
    .webform-client-form { display: inline-block; width: 100%;  position: relative; font-family: $OS; font-size: 16px; font-weight: 300;
      label { color: $white; font-weight: 300;  }
      .form-item {  width: 48%;
        &:last-child {  }
      }
      .footer-msg { position: absolute; top: 0; right: 0;
        .form-control { height: 143px; }
      }
      .form-control {  border-radius: 0; height:50px;  }
      .form-actions { float: right;
        .webform-submit {  @include submit-button(25px, 0, $green-light, none, 16px); padding: 10px 55px; margin-right: 0;}
      }
    }
  }
  .block--ornitologorgpl, .block--ornitolog-ekspert { padding-left: 25px; margin-top: 70px; font-size: 16px;
    h4 { font-size: 24px; text-transform: uppercase; margin-bottom: 25px; font-family: $CR;}
    a { color: $white;
      &:hover { color: $blue; cursor: pointer;}
      img { margin-left: 15px; }
    }
    p { color: #fff; margin-bottom: 5px;}
    .social { margin-top:30px;
      p { margin-bottom: 25px; }
    }
    .block__content {
      h4 { padding: 25px 0 20px; margin: 0;}
    }
  }
}

svg:not(:root) {
  overflow: visible;
}

.logged-in .main-container.container { margin-top: 140px;}
.not-logged .top-content { margin-top: 104px;}
.not-front {
  .main-container.container { margin-top: 140px;}
  .block-menu { float: right;}
}

@mixin social-icon($bgpos) {
  position: relative; width: 100px;
  &:before {
    width: 100px;
    height: 32px;
    top: -3px;
    left: 0;
    position: absolute;
    content: "";
    background: url(/sites/all/themes/bootstrap_ornitolog/images/share-btn.png);
    background-repeat: no-repeat;
    background-position: $bgpos;
  }
}

.node-type-offer {
  .sharethis-buttons {
    .sharethis-wrapper { margin-bottom: 36px; padding-top: 36px;}

    span.stMainServices.st-facebook-counter {
      @include social-icon(0 0);
    }

    span.stMainServices.st-twitter-counter {
      @include social-icon(-110px 0);
    }

  }
  .field-name-article-date, .field-name-field-image { display: none; }
  .field-name-field-lead { clear: both; font-weight: bold; font-size: 18px;}
  .field-name-field-bg-img {
    .field-item.even {width: 1920px; max-width: 100%; margin: 0 auto; }
    img { height: inherit; max-width: 100%;}
  }
  .field-name-field-lead { font-size: 24px; padding-bottom: 30px;}

  .page-header { font-family: $CR; border: none; width: 1170px; margin: 0 auto; float: none; color: #fff; font-size: 50px; font-weight: bold; padding: 70px 15px 0; max-width: 100%;}
  h2 { font-size: 30px; font-weight: bold; margin-top: 40px;}
  ul li {padding: 0.2em 0;}
  .field-name-body ul {list-style-image:  url('../../assets/images/markup_green.png');
    ul { list-style: none; padding-top: 10px;
      li { padding: 0;}
    }
  }
  a { color: $blue;}
  & body { position: relative;}
  .top-content { margin-top: 104px;}
  .main-container { position: absolute; width: 100%;
    &.container { margin-top: 0;}
  }
  footer { margin-top: 50px;}
}

.slick-arrow::before { color: $blue;}
.view-ekspert-partnerzy .slick__slide{ width: 240px;
  .slide__content { border: none;
    &:hover { box-shadow: none;
    img { opacity: 0.7;}
    }
  }
  .views-field-field-logo-partner {
    .field-content { height: 100px;}
  }
}


.not-logged-in .block.block-git-ornitolog-landing {
  .landing-video {
    margin-top: 104px;
    .block__content { width: 100%;
      video { float: left;}
    }
  }
}

.block.block-git-ornitolog-landing {
  .block__content {
    position: relative;
    #video-header { float: left; width: 100%; position: relative;
      .container { padding: 0;}
      .header-text { position: absolute; top: 50%; transform: translateY(-50%);  z-index: 99; padding-left:15px;
      h2, h3 {color: #fff;}
        h2 {font-size: 40px; font-weight: bold;}
        h3 { font-family: $OS; font-size: 21px;}
        ul { color: #fff; margin: 0; padding: 30px 0 0; list-style-image:  url('../../assets/images/markup_green.png');
          > h3 { font-weight: bold; text-transform: uppercase; font-size: 28px;}
          li { margin-left: 54px; padding-left: 10px;
            h3 { margin-top: 5px;}
          }
        }
        a.btn { display: inline-block; color: $white; border: 1px solid $white; border-radius: 0;  text-transform: uppercase; font-size: 16px; font-family: $OS; font-weight: 300; padding: 12px 25px; margin-top: 40px; cursor: pointer;
          &:hover {background: rgba(256,256,256, 0.1);}
        }
      }
      &:before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: "";
        background: url('../../assets/images/bg5.png');
        opacity: 0.3;
      }
    }
  }
}

.front .block.block-block.block--none { width: 100%; float: left;}

div#slick-views-ekspert-partnerzy-1, .view-id-ekspert_partnerzy { width: 1080px; max-width:100%; margin: 0 auto;}

.view-id-contact_map.view-display-id-block_2 {
  background: #ebebeb; padding: 30px 25px 25px; float: left; margin-top: 100px;
  h2 { font-weight: normal; color: #000; margin-top:0;}
  ul { padding: 0; list-style: none;
    li {  border-bottom: solid 1px #b6b6b6; float: left; width: 100%; padding: 0;}
  }
  a { font-family: $CR; font-size: 20px; text-transform: uppercase; padding: 14px 10px;
    &:hover, &:active, &:focus { background: #d7d7d7; text-decoration: none;}
  }
}


//volunteers

.page-wolontariusze {
  line-height: normal;
  .main-container.container { margin-top: 170px;}
  h1, h2, h3, h4 { font-family: Roboto, sans-serif;}
  h2 { font-size:48px; font-weight: 200; color: #455a64; }
  h3 { font-size: 24px; color: #455a64;}
  p { font-size: 15px; font-family: Roboto, sans-serif; font-weight: 100;  }
  .region-nodebg {  background-color: $volnteer-blue; position: fixed; z-index: 1020; top: 105px; width: 100%; }
  .view-dla-wolonotariuszy {
    overflow: hidden;
    .full-block { position: relative;
      h3 {display:inline-block; text-transform: uppercase; border-bottom: 2px solid; font-weight: 100; }
    }
    .text-block {  margin-top: 50px; margin-bottom:120px; color: #fff; z-index: 9; }
    .spolecznosc_img { position: absolute; overflow: hidden; z-index: -0; right: 0; bottom: 0; }
    p { color: #fff;}
    .big-text p { font-size: 6vh; overflow:hidden; clear: both;}
    .small-text { margin: 40px 0;
      p { font-size: 24px; font-weight: 100;}
    }
    #spolecznosc h3 { color: #3dc2cc;}
    #zasady_i_korz h3 { color: #e3cd2b;}
    #korzysci_dod h3 { color: #f268ba;}

    &.view-id-dla_wolonotariuszy {
      &.view-display-id-block_2 { float: left; width: 100%; background-image: url('/sites/ornitolog/files/spol.png'); background-size: 100% 100%; }
      &.view-display-id-block_4 { float: left; width: 100%; background-image: url('/sites/ornitolog/files/zasady.png'); background-size: 100% 100%; }
      &.view-display-id-block_5 { float: left; width: 100%; background-image: url('/sites/ornitolog/files/korzysci.png');  background-size: 100% 100%; }
    }
  }
  .full-block, .before-footer {
    .btn { border: 1px solid #fff; padding: 15px 40px; color: #fff; text-transform: uppercase; font-size: 24px; line-height: 25px; transition: all 500ms;
      span { font-size: 16px; font-weight: 100;}
    }
  }
  .before-footer {
    .btn {
      &:hover {
        background-color: #fff; color: #3b5998; border: 1px solid; transition: all 500ms;
      }
    }
  }
  div#idea { background-image: url(/sites/ornitolog/files/idea-1.jpg); padding-bottom: 200px; background-size: 100% 100%;
    h3 { color: #76cc3d; }
  }
  section#idea_dzialania { background-image: url('/sites/ornitolog/files/idea-1.png'); background-size: 100% 100%; }
  .view-dla-wolonotariuszy.view-id-dla_wolonotariuszy.view-display-id-block_2 { float: left; width: 100%; background-image: url('/sites/ornitolog/files/spol.png'); background-size: 100% 100%; }
  .text-idea {
    h3 { margin-top: 120px;}
    .sub-header { font-size: 24px; }
    ol { margin-bottom: 140px; margin-top: 30px; font-weight: 200;}
  }
  .idea-block { margin-top: 120px;}
  .block.block-views.contextual-links-region { clear: both;}
  .volunteer  { text-align:left; margin-top: -5px; margin-bottom:-5px; padding-top: 20px; padding-bottom: 40px;
    img { border-radius: 100%; max-width: 100%; height: auto;}
    .head { overflow: hidden; margin-bottom: 20px;
      h4 {  font-size: 24px;  margin-bottom: 0;  margin-top: 20px; }
    }
  }
  .people { border-top: 5px solid #acd373; border-bottom: 5px solid #acd373; }
  .lead-zasady { margin-top: 70px; margin-bottom: 70px; }
  .zasady-tytuł { padding: 30px 0; font-size: 48px; background-color: #40affc; color: #fff; }
  .zasady-nagrody {
    .nagrody { margin-bottom: 75px; overflow: hidden; clear: both;
      .nagroda {
        .pkt {  padding: 20px;  background-color: #40affc;  margin-top: 30px;
          p { font-size:60px; color: #fff;
            span { font-size: 24px; font-weight: 100; display: block; margin-top: -10px;}
          }
        }
        &:first-child { padding-left: 0;}
        &:last-child { padding-right: 0;}
        .img { position: relative;  overflow: hidden; height: 300px; background: #40affc; border-top: 2px solid #fff;
          img { position: absolute; height: initial; left:0; right:0; bottom:0; margin:0 auto;}
        }
        .bottom-block { margin-top: 15px;
          p { text-align: center; font-weight: 500;}
        }
      }
    }
  }
  .szkolenia { padding-top: 75px; clear: both;
    h3 { a { color: #000;}}
    p { color: #455a64;}
  }
  #dolacz {
    background-color: #0072bc;
    color: #fff;
    padding: 100px 0;
    margin-top: 75px;
  }
}

.node-type-notification {
  .field-name-field-multi-image {
    text-align: center;
    margin-bottom: 30px;
  }
}

@media (max-width: 1200px) {
  .node-type-offer .page-header { width: 970px;}
}

@media (max-width: 992px) {
  header#navbar {
    > .container { width: 1000px; max-width: 100%;}
    .navbar-collapse {
      position: static;
      background: #fff;
      border: none;
    .block-menu {
      ul.menu.nav { margin: 0; width: 100%; text-align: center;
      li { display: block;}
      }
    }
    }
  }
  .top-content {
    #onas {
      > .container {width:1000px; max-width:100%;}
      .about-text { padding-left: 15px;}
    }
  }
  .top-content .view-ekspert-oferta .oferta img { width: 100%;}
  .node-type-offer .page-header { width: 750px;}

  .node-type-offer .field-name-field-bg-img img { width: 100vw; min-height: 150px; object-fit: cover;}
  .view-id-contact_map.view-display-id-block_2 { margin-top: 30px;}
}

@media (max-width: 768px) {
  .top-content .view-odzia-y .view-content {
    .map { padding: 0;
      svg{ padding: 0;}}
    .data-place { padding: 0;}
    .oddzial { padding: 10px;}
    //.field-collection-item-field-social-media .field-type-link-field { margin-left: 20px;}
  }
  .top-content #onas {
    .about-text { padding: 0; }
      img {
        &:first-child { top: 0;}
        &:last-child { top: 120px;}
      }

  }
  .baner-prow {
    .block__content {
      > .container { padding: 0;}
    }
  }
  .top-content .view-id-ebook {
    .view-content { margin-top: 30px; }
    .node-webform {
      .group-header, .group-right, .group-left { width: 100%;}
      .group-right {.form-control, .form-submit { width: 480px; max-width: 100%; margin: 0 auto;}
      }
    }
  }
  footer {
    .block-views, .block-block { padding: 0;}
    .view-footer-form .webform-client-form {
      .form-item { width: 480px; max-width: 100%; }
      .footer-msg { position: static;}
      .form-actions { margin-left: 0; max-width: 480px;}
    }
  }
  a.logo.navbar-btn.pull-left { width: 240px;}
  .top-content .view-ekspert-oferta { width: 100%; padding: 0;}
  .node-type-offer .page-header { width: 100%;}

}
@media (max-width: 580px) {
  .top-content .baner-prow .prow-head h2 { clear: left; padding-top: 0;}
  .top-content .view-ekspert-oferta {
    .oferta .oferta-tekst { width: 100%;}
  }

  .node-type-offer .page-header { font-size: 36px; padding-top: 30px;}
}

@media (max-width: 480px) {
  .top-content .view-ekspert-oferta {
    p, .more { display: none;}
  }
  .block.block-git-ornitolog-landing .block__content #video-header .header-text { top:0; transform: none;
    h2 {font-size: 26px; margin-top: 5px;}
    h3 { font-size: 14px; margin:0;}
    a.btn.btn-lg { margin-top: 20px; }
    }

}

@media (max-width: 400px) {
  .top-content .view-odzia-y .view-content ul#pl-mz-nav {
    li:nth-of-type(2) {border-right: none;}
    li:last-of-type { width: 100%; display: block; border-top: solid 2px white; border-bottom: solid 3px #fff;}
  }
}